/*
 * @Author: fengguangyu 941627576@qq.com
 * @Date: 2022-12-18 17:02:47
 * @LastEditors: Zhang Jiinghan jinghan@deeplang.ai
 * @LastEditTime: 2023-07-21 11:44:35
 * @FilePath: /deep-lang-wantwords/src/App.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { Suspense } from 'react';

import { renderRoutes } from 'react-router-config';
import { BrowserRouter } from 'react-router-dom';
import routes from './routers';


import HomeContext from './pages/home/home.context';
import { HomeInitialState, initialState } from './pages/home/home.state';

import { v4 as uuidv4 } from 'uuid';
import { reqNewSession, reqUpdateSession } from './api';
import { useCreateReducer } from './hooks/useCreateReducer';
import { saveFolders } from './utils/app/folders';
import { savePrompts } from './utils/app/prompts';
import { saveSession, saveSessions } from './utils/app/session';

import { Session } from './types/chat';
import { FolderInterface, FolderType } from './types/folder';
import { Prompt } from './types/prompt';

import toast, { Toaster } from 'react-hot-toast';
import './App.less';

const App = () => {

  const contextValue = useCreateReducer<HomeInitialState>({
    initialState,
  });

  const {
    state: {
      apiKey,
      lightMode,
      folders,
      sessions,
      prompts,
      userId
    },
    dispatch } = contextValue;

  // 创建一个新的对话/聊天
  const handleNewSession = async () => {
    const result: any = await reqNewSession(userId);
    if (result.status === 0) {
      toast.success('新建会话成功！');
      const newSession: Session = {
        sessionId: result.sessionId,
        sessionTitle: '未命名',
        // action: '_ask1',
        // model: 'dlm-1',
        // meta: {
        //   id: uuidv4(),
        //   content: {
        //     content_type: 'text',
        //     session: [],
        //     parts: []
        //   }
        // }
      };

      // 插入到对话列表中
      const updatedSessions = [...sessions, newSession];

      dispatch({ field: 'selectedSession', value: newSession }); // 被选中的对话
      dispatch({ field: 'sessions', value: updatedSessions }); // 全部的对话列表

      // 存储被选中的对话和对话列表
      // saveConversation(newConversation);
      // saveConversations(updatedConversations);
      saveSession(newSession);

      dispatch({ field: 'loading', value: false });
    }
    else {
      toast.error('新建会话失败！');
    }
  };

  // -------------------------- 文件夹操作 --------------------------

  const handleCreateFolder = (name: string, type: FolderType) => {
    const newFolder: FolderInterface = {
      id: uuidv4(),
      name,
      type,
    };

    const updatedFolders = [...folders, newFolder];

    dispatch({ field: 'folders', value: updatedFolders });
    saveFolders(updatedFolders);
  };

  const handleDeleteFolder = (folderId: string) => {
    const updatedFolders = folders.filter((f) => f.id !== folderId);
    dispatch({ field: 'folders', value: updatedFolders });
    saveFolders(updatedFolders);

    const updatedSessions: Session[] = sessions.map((s) => {
      // if (s.folderId === folderId) {
      //   return {
      //     ...s,
      //     folderId: null,
      //   };
      // }

      return s;
    });

    dispatch({ field: 'sessions', value: updatedSessions });
    saveSessions(updatedSessions);

    const updatedPrompts: Prompt[] = prompts.map((p) => {
      if (p.folderId === folderId) {
        return {
          ...p,
          folderId: null,
        };
      }

      return p;
    });

    dispatch({ field: 'prompts', value: updatedPrompts });
    savePrompts(updatedPrompts);
  };

  const handleUpdateFolder = (folderId: string, name: string) => {
    const updatedFolders = folders.map((f) => {
      if (f.id === folderId) {
        return {
          ...f,
          name,
        };
      }

      return f;
    });

    dispatch({ field: 'folders', value: updatedFolders });

    saveFolders(updatedFolders);
  };

  // -------------------------- 会话操作 --------------------------
  const handleUpdateSession = async (
    renameValue: string,
    session: Session | undefined
  ) => {
    const result: any = await reqUpdateSession(renameValue, session?.sessionId);
    if (result.status === 0) {
      // message.success('修改会话名称成功！');
      const updatedSession = {
        ...session,
        sessionTitle: renameValue
      };
      dispatch({
        field: 'selectedSession',
        value: updatedSession
      });
      saveSession(updatedSession);
    }
    else {
      // message.error('修改会话名称失败！');
    }
  };

  // -------------------------- 模型获取 --------------------------
  const handleSelectSession = async (session: Session) => {
    dispatch({
      field: 'selectedSession',
      value: session,
    });
    await saveSession(session);
  };

  return (
    <HomeContext.Provider
      value={{
         ...contextValue,
        handleNewSession,
        handleCreateFolder,
        handleDeleteFolder,
        handleUpdateFolder,
        handleSelectSession,
        handleUpdateSession,
      }}>
      {/* <RouterComponent></RouterComponent> */}
      <BrowserRouter>
        <Suspense fallback={<h2>Loading..</h2>}>
          {renderRoutes(routes)}
          <Toaster /> 
        </Suspense>
      </BrowserRouter>
    </HomeContext.Provider>

  );
}

export default App;

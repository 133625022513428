/*
 * @Author: fengguangyu 941627576@qq.com
 * @Date: 2022-12-16 18:48:45
 * @LastEditors: Zhang Jiinghan jinghan@deeplang.ai
 * @LastEditTime: 2023-07-03 16:59:22
 * @FilePath: /deep-lang-wantwords/src/api/request.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
//对axios进行二次封装,将刚才下载好的axios导入进来
import axios from "axios";
import toast from "react-hot-toast";
// import { usePublish } from "../hooks/usePubSub";

//1.利用axios对象的方法create,去创建一个axios实例
//2.requests就是axios,只不过稍微配置一下
let baseURL = '/api';
let requestValue: any = {};
// 环境的切换
if (process.env.NODE_ENV === 'development') {
	baseURL = 'http://mchat.deeplang.tech';
} else if (process.env.NODE_ENV === 'test') {
	baseURL = '/';
} else if (process.env.NODE_ENV === 'production') {
	baseURL = '/';
}
const service = axios.create({
	baseURL: baseURL,
	timeout: 60000
});

service.defaults.withCredentials = false;
service.defaults.headers['Content-Type'] = 'application/json;';

//请求拦截器:在发请求之前，请求拦截器可以检测到,可以在请求发出去之前做一些事情
service.interceptors.request.use((config) => {
	config.headers.set('Content-Type', 'application/json');
	//config是个配置对象，对象里面有一个属性很重要，headers请求头
	return config;
});

// post请求头配置
// service.defaults.headers['Content-Type'] = 'application/json;';
//响应拦截器：包含两个函数（一个是成功返回的函数，一个是失败的返回的函数）
service.interceptors.response.use(async (res) => {
	// const { status } = res.data;
	return res.data;
}, (error) => {
	if (error.code === "ECONNABORTED" || error.message.indexOf('timeout') > -1) {
		toast.error('网络开小差了');
	}
	// 跨域问题
	if (error.code === "ERR_NETWORK") {
		toast.error('系统开小差了');
	}
	return Promise.reject(new Error(error));
});


/** 
 * get方法，对应get请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function get(url: string, params: any) {
	requestValue = {
		method: 'get',
		url,
		params
	};
	return new Promise((resolve, reject) => {
		service.get(url, {
			params: params
		})
			.then(res => {
				resolve(res);
			})
			.catch(err => {
				reject(err)
			})
	});
}
/** 
 * post方法，对应post请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function post(url: string, params: any) {
	requestValue = {
		method: 'post',
		url,
		params
	};
	return new Promise((resolve, reject) => {
		service.post(url, params)
			.then(res => {
				resolve(res);
			})
			.catch(err => {
				reject(err)
			})
	});
}


/*
 * @Author: Zhang Jiinghan jinghan@deeplang.ai
 * @Date: 2023-06-06 10:34:06
 * @LastEditors: Zhang Jiinghan jinghan@deeplang.ai
 * @LastEditTime: 2023-07-11 14:53:27
 * @FilePath: /deep-chat/utils/app/session.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { reqSessions } from '../../api';
import { Session } from '../../types/chat';

export const getSessions = async (userId: any) => {
    if(userId) {
        const result: any = await reqSessions(userId);
        if (result.status === 0) {
            return result.sessionHistory;
        }
    }
    else {
        return [];
    }
}

export const updatedSession = (
    updatedSession: Session,
    allSessions: Session[],
) => {
    // 获取更新后的会话列表
    const updatedSessions = allSessions.map((c) => {
        if (c.sessionId === updatedSession.sessionId) {
            return updatedSession;
        }
        return c;
    });

    // 保存新的会话
    saveSession(updatedSession);
    saveSessions(updatedSessions);

    return {
        single: updatedSession,
        all: updatedSessions,
    };
};

export const saveSession = async (session: Session | undefined) => {
    if (session) {
        localStorage.setItem('selectedSession', JSON.stringify(session));
    }
    else {
        localStorage.removeItem('selectedSession');
    }
};

export const saveSessions = (session: Session[]) => {
    localStorage.setItem('sessionHistory', JSON.stringify(session));
};

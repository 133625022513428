/*
 * @Author: Zhang Jiinghan jinghan@deeplang.ai
 * @Date: 2023-06-25 11:20:56
 * @LastEditors: Zhang Jiinghan jinghan@deeplang.ai
 * @LastEditTime: 2023-07-21 16:33:21
 * @FilePath: /deep-chat/services/index.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { get, post } from "./service";

// 登录
export const reqLogin = (userName: any, password: any) => post('/logindemo', { userName, password });

// 新建会话
export const reqNewSession = (userId: any) => get('/newSession', { userId });

// 删除会话
export const reqDeleteSession = (sessionId: any) => post('/sessionDelete', { sessionId });

// 修改会话名称(编辑会话)
export const reqUpdateSession = (title: any, sessionId: any) => post('/changeSessionTitle', { title, sessionId });

// 获取会话列表
export const reqSessions = (userId: any) => get('/sessionHistory', { userId });

// 获取聊天详情
export const reqConversationDetail = (sessionId: number, userId: number) => get('/sessionDetail', { sessionId, userId });

// 对话
export const reqChat = (params: any) => post('/chatPro', params);

export const reqDeleteChat = (chatId: any) => post('/chatDelete', { chatId });

// 点赞某条对话
export const reqLikeMessage = (chatId: any) => get('/like', { chatId });

// 反馈or点踩某条对话
export const reqFeedbackMessage = (params: any) => post('/unlike', params);
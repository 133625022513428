/*
 * @Author: fengguangyu 941627576@qq.com
 * @Date: 2022-12-16 18:48:45
 * @LastEditors: Lemon.Feng 
 * @LastEditTime: 2023-06-30 00:01:22
 * @FilePath: /deep-lang-wantwords/src/routers.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/* eslint-disable max-len */
import { lazy } from 'react';

const Routers = [
	{
		path: '/',
		name: 'index',
		key: 'index',
		exact: true,
		component: lazy(() => import(/* webpackChunkName: "index" */'./pages/home')),
		meta: {
			title: '首页'
		}
	},
	{
		path: '/index',
		name: 'index',
		key: 'index',
		component: lazy(() => import(/* webpackChunkName: "index" */'./pages/home')),
		meta: {
			title: '首页'
		}
	},
	{
		path: '/login',
		name: 'login',
		key: 'login',
		component: lazy(() => import(/* webpackChunkName: "index" */'./pages/login')),
		meta: {
			title: '登录'
		}
	}
];
export default Routers;
